export default function usePlans(){
    const appConfig = useRuntimeConfig();

    const plans = {
        '1' : {
            'id': appConfig.public.prices.pro,
            'key' : 'pro',
            'promo' : false,
            'price' : 99,
            'price_qg' : 49.50,
            'frequency': 'mon',
            'label' : 'Pro Plan',
            'description' : 'Best option for people running a single ad account, or small businesses',
            'basicInclusions' : [
                '2 Dream Buyer Avatars',
                '4 Long Form Facebook Ads',
                '6 Direct Response Headline Sets',
                '3 HVCO Title Sets'
            ],
            'inclusions' : [
                {
                    title: '2 Dream Buyer Avatars',
                    description: 'Create a detailed buyer persona including their deepest desires, beliefs, fears, pains, relationships, goals and emotions.',
                    list: [
                        'Uncover ‘<b>obsessed stalker</b>’ level insights to boost conversion rates on ads and funnels',
                        '<b>Discover Deep Seeded</b> Buying triggers to increase ad ROI from all marketing campaigns.',
                        'Find out <b>EXACTLY</b> what your market wants <b>(so you can sell it to them)</b>.',
                    ]
                },
                {
                    title: 'Facebook Ads',
                    description: 'Generate hundreds of ad variations for lead gen and ecommerce with captivating headlines.',
                    list: [
                        '<b>4 Long Form</b> Facebook Ads',
                        '<b>200+</b> Facebook Ad Headlines',
                        '<b>30 High CTR</b> Link Descriptions',
                        '<b>1000+</b> Ad combinations',
                    ]
                },
                {
                    title: 'Direct Response Headlines',
                    description: 'Create powerful eyebrow copy, headlines and subheadlines designed to capture attention and drive conversions on your landing pages and funnels.',
                    list: [
                        '<b>6 Direct Response</b> Headline Sets',
                        '<b>250+ Killer Headlines</b> Combinations Per Set',
                        '<b>Opt-in page</b> Headlines',
                        '<b>Webinar Registration</b> Pages Headlines',
                        '<b>Landing pages</b> Headlines',
                    ]
                }
            ]
        },
        '2' : {
            'id' : appConfig.public.prices.agency,
            'key' : 'agency',
            'promo' : false,
            'price' : 397,
            'price_qg' : 198.50,
            'frequency': 'mon',
            'label' : 'Agency Plan',
            'description' : 'Best option for people running multiple ad accounts, agencies or anyone wanting to run lots of ad variations.',
            'basicInclusions' : [
                '8 Dream Buyer Avatars',
                '16 Long Form Facebook Ads',
                '24 Direct Response Headline Sets',
                '12 HVCO Title Sets'
            ],
            'inclusions' : [
                {
                    title: '8 Dream Buyer Avatars',
                    description: 'Create a detailed buyer persona including their deepest desires, beliefs, fears, pains, relationships, goals and emotions.',
                    list: [
                        'Uncover ‘<b>obsessed stalker</b>’ level insights to boost conversion rates on ads and funnels',
                        '<b>Discover Deep Seeded</b> Buying triggers to increase ad ROI from all marketing campaigns.',
                        'Find out <b>EXACTLY</b> what your market wants <b>(so you can sell it to them)</b>.',
                    ]
                },
                {
                    title: 'Facebook Ads',
                    description: 'Generate hundreds of ad variations for lead gen and ecommerce with captivating headlines.',
                    list: [
                        '<b>16 Long Form</b> Facebook Ads',
                        '<b>200+</b> Facebook Ad Headlines',
                        '<b>30 High CTR</b> Link Descriptions',
                        '<b>2000+</b> Ad combinations',
                    ]
                },
                {
                    title: 'Direct Response Headlines',
                    description: 'Create powerful eyebrow copy, headlines and subheadlines designed to capture attention and drive conversions on your landing pages and funnels.',
                    list: [
                        '<b>24 Direct Response</b> Headline Sets',
                        '<b>250+ Killer Headlines</b> Combinations Per Set',
                        '<b>Opt-in page</b> Headlines',
                        '<b>Webinar Registration</b> Pages Headlines',
                        '<b>Landing pages</b> Headlines',
                    ]
                }
            ]
        },
        '3' : {
            'id' : appConfig.public.prices.free,
            'key' : 'free',
            'promo' : 'trial',
            'price' : 0,
            'price_qg' : 0,
            'frequency': 'mon',
            'label' : 'Basic Plan',
            'description' : '7 Days Of Limited Functionality, Perfect for Small Businesses and New Advertisers',
            'inclusions' : [
                {
                    title: 'Dream Buyer Avatars (Limited Access)',
                    description: '',
                    list: [
                        '<b>1 Buyer Avatar:</b> Create detailed buyer personas with insights into their desires, beliefs, fears, and goals.',
                        '<b>Limited Insights:</b> Basic access to buyer triggers and motivations to help optimize ad content.'
                    ]
                }
            ]
        },
        '4' : {
            'id': appConfig.public.prices.pro_yearly,
            'key' : 'pro_yearly',
            'promo' : false,
            'price' : 948,
            'price_qg' : 474,
            'frequency': 'year',
            'label' : 'Pro Plan',
            'description' : 'Best option for people running a single ad account, or small businesses',
            'basicInclusions' : [
                '2 Dream Buyer Avatars',
                '4 Long Form Facebook Ads',
                '6 Direct Response Headline Sets',
                '3 HVCO Title Sets'
            ],
            'inclusions' : [
                {
                    title: '2 Dream Buyer Avatars',
                    description: 'Create a detailed buyer persona including their deepest desires, beliefs, fears, pains, relationships, goals and emotions.',
                    list: [
                        'Uncover ‘<b>obsessed stalker</b>’ level insights to boost conversion rates on ads and funnels',
                        '<b>Discover Deep Seeded</b> Buying triggers to increase ad ROI from all marketing campaigns.',
                        'Find out <b>EXACTLY</b> what your market wants <b>(so you can sell it to them)</b>.',
                    ]
                },
                {
                    title: 'Facebook Ads',
                    description: 'Generate hundreds of ad variations for lead gen and ecommerce with captivating headlines.',
                    list: [
                        '<b>4 Long Form</b> Facebook Ads',
                        '<b>200+</b> Facebook Ad Headlines',
                        '<b>30 High CTR</b> Link Descriptions',
                        '<b>1000+</b> Ad combinations',
                    ]
                },
                {
                    title: 'Direct Response Headlines',
                    description: 'Create powerful eyebrow copy, headlines and subheadlines designed to capture attention and drive conversions on your landing pages and funnels.',
                    list: [
                        '<b>6 Direct Response</b> Headline Sets',
                        '<b>250+ Killer Headlines</b> Combinations Per Set',
                        '<b>Opt-in page</b> Headlines',
                        '<b>Webinar Registration</b> Pages Headlines',
                        '<b>Landing pages</b> Headlines',
                    ]
                }
            ]
        },
        '5' : {
            'id' : appConfig.public.prices.agency_yearly,
            'key' : 'agency_yearly',
            'promo' : false,
            'price' : 3588,
            'price_qg' : 1794,
            'frequency': 'year',
            'label' : 'Agency Plan',
            'description' : 'Best option for people running multiple ad accounts, agencies or anyone wanting to run lots of ad variations.',
            'basicInclusions' : [
                '8 Dream Buyer Avatars',
                '16 Long Form Facebook Ads',
                '24 Direct Response Headline Sets',
                '12 HVCO Title Sets'
            ],
            'inclusions' : [
                {
                    title: '8 Dream Buyer Avatars',
                    description: 'Create a detailed buyer persona including their deepest desires, beliefs, fears, pains, relationships, goals and emotions.',
                    list: [
                        'Uncover ‘<b>obsessed stalker</b>’ level insights to boost conversion rates on ads and funnels',
                        '<b>Discover Deep Seeded</b> Buying triggers to increase ad ROI from all marketing campaigns.',
                        'Find out <b>EXACTLY</b> what your market wants <b>(so you can sell it to them)</b>.',
                    ]
                },
                {
                    title: 'Facebook Ads',
                    description: 'Generate hundreds of ad variations for lead gen and ecommerce with captivating headlines.',
                    list: [
                        '<b>16 Long Form</b> Facebook Ads',
                        '<b>200+</b> Facebook Ad Headlines',
                        '<b>30 High CTR</b> Link Descriptions',
                        '<b>2000+</b> Ad combinations',
                    ]
                },
                {
                    title: 'Direct Response Headlines',
                    description: 'Create powerful eyebrow copy, headlines and subheadlines designed to capture attention and drive conversions on your landing pages and funnels.',
                    list: [
                        '<b>24 Direct Response</b> Headline Sets',
                        '<b>250+ Killer Headlines</b> Combinations Per Set',
                        '<b>Opt-in page</b> Headlines',
                        '<b>Webinar Registration</b> Pages Headlines',
                        '<b>Landing pages</b> Headlines',
                    ]
                }
            ]
        },
    };

    return { plans };
}